body {
  margin: 0%;
  padding: 0%;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");

.App {
  place-items: center;
  display: grid;
  width: 100vw;
  height: 100vh;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  color: #1566bf;
}

.notice {
  padding: 20px;
  text-align: center;
}

.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.joinChatContainer img {
  width: 250px;
  height: 250px;
}

.joinChatContainer h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.joinChatContainer input {
  outline-color: #3e9efa;
  width: 210px;
  height: 40px;
  margin: 7px;
  padding: 5px;
  border: 2px solid #1566bf;
  border-radius: 5px;
  font-size: 1rem;
}

.joinChatContainer select {
  outline-color: #3e9efa;
  width: 225px;
  height: 55px;
  margin: 7px;
  padding: 5px;
  border: 2px solid #1566bf;
  border-radius: 5px;
  font-size: 1rem;
}

.joinChatContainer button {
  width: 225px;
  height: 50px;
  margin: 7px;
  padding: 5px;
  background: #1566bf;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
}

.joinChatContainer button:hover {
  background: #3e9efa;
}

.chat-window {
  width: 350px;
  height: 580px;
  padding-top: 20px;
}

.chat-window p {
  margin: 0;
}

.chat-window .chat-header {
  position: relative;
  height: 45px;
  background: #1566bf;
  cursor: pointer;
  border-radius: 6px;
}

.chat-window .chat-header p {
  display: block;
  padding: 0 1em 0 2em;
  font-weight: 700;
  line-height: 45px;
  color: #fff;
}

.chat-window .chat-body {
  position: relative;
  height: calc(600px - (45px + 70px));
  background: #fff;
  border: 1px solid #1566bf;
}

.chat-window .chat-body .message-container {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}

.chat-window .chat-body .message {
  display: flex;
  height: auto;
  padding: 10px;
}

.chat-window .chat-body .message .message-content {
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 270px;
  height: auto;
  min-height: 40px;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  word-break: break-word;
}

#you {
  justify-content: flex-start;
}

#you .message-content {
  justify-content: flex-start;
  background-color: #8a15bf;
}

#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-end;
}

#other .message-content {
  justify-content: flex-end;
  background-color: #10a37f;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 0.875rem;
}

.chat-window .chat-footer {
  display: flex;
  height: 50px;
  border: 1px solid #1566bf;
  border-top: none;
}

.chat-window .chat-footer input {
  outline: none;
  flex: 85%;
  height: 100%;
  padding: 0 0.7em;
  border: 0;
  border-right: 1px dotted #1566bf;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}

.chat-window .chat-footer button {
  place-items: center;
  outline: none;
  display: grid;
  flex: 15%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  border: 0;
  font-size: 2rem;
  color: #1566bf;
}

.chat-window .chat-footer button:hover {
  color: #3e9efa;
}

.hide {
  opacity: 0 !important;
}